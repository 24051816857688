import * as React from "react"
import { Input, InputAdornment, IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import firebase from "firebase";
import { firebaseInit } from "../../../config";
import SCButton from "../../buttons/SCButton";


const ChangePasswordForm = () => {

  const charEspecial = /[@$?¡\-_]/;
  const alphanumericRegex = /[0-9]/;
  const upperCaseRegex = /[A-Z]/;
  const acentos = /[áéíóúÁÉÍÓÚ]/;

  const [passwordState, setPasswordState] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  const [success, setSuccess] = React.useState()
  const [error, setError] = React.useState()
  const [user, setUser] = React.useState(null)


  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(firebase.auth().currentUser)
      } else console.log('No logueado')
    })
  }, [])




  React.useEffect(() => {
    passwordState.currentPassword = ""
    passwordState.newPassword = ""
    passwordState.confirmPassword = ""
  }, [success])

  // React.useEffect(() => {
  //   firebase.auth().updatePassword(user, newPassword).then(() => {
  //     // Update successful.
  //   }).catch((error) => {
  //     // An error ocurred
  //     // ...
  //   });
  // }, [])

  const verifyPasswordCurrent = () => {
    if (passwordState.currentPassword && passwordState.newPassword && passwordState.confirmPassword != '') {
      //Lógica cambio de contraseña
      firebase.auth().onAuthStateChanged(() => {
        const cred = firebase.auth.EmailAuthProvider.credential(user.email, passwordState.currentPassword)
        user.reauthenticateWithCredential(cred)
          .then(() => {
            return updatePassword()
          })
          .catch(error => {
            console.log(error)
            setError('Contraseña actual Incorrecta')
          })
      })
    } else {
      setError('Ingresa una nueva contraseña')
    }
  }

  const handleUpdatePassword = (value, property) => {
    if(success) setSuccess()
    if(error) setError()
    setPasswordState({ ...passwordState, [property]: value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") })
  }

  const updatePassword = () => {
    if(success) setSuccess()
    if(error) setError()
    if (passwordState.newPassword == passwordState.confirmPassword) {
      if(passwordState.currentPassword == passwordState.newPassword){
        setError('Ingrese una contraseña diferente a la actual')
        return false
      }
      console.log(acentos.test(passwordState.newPassword))
      if (acentos.test(passwordState.newPassword)) {
        setError("Los acentos no están permitidos")
        return false
      }
      if (!charEspecial.test(passwordState.newPassword)) {
        setError("La contraseña debe de contener al menos un caracter especial: @ $ ? ¡ \ - _")
        return false
      }
      if (!upperCaseRegex.test(passwordState.newPassword)) {
        setError('La contraseña debe de tener al menos una letra en mayúscula')
        return false
      } 
      if (!alphanumericRegex.test(passwordState.newPassword)) {
        setError('La contraseña debe contener letras y números.')
        return false
      }
      if (passwordState.newPassword.length < 8) {
        setError('La contraseña debe mayor a 8 caracteres');
        return false
      }
      
      user.updatePassword(passwordState.newPassword)
        .then(() => {
          console.log('La contraseña ha sido actualizada')
          setSuccess(true)
          return true
        })
        .catch((error) => {
          console.log(error)
          switch (error.code) {
            case 'auth/too-many-requests':
              setError('Demasiados intentos. Vuelva a intentarlo en unos minutos.')
              break;
            default:
              setError('Ha ocurrido un error, intente nuevamente.')
              break;
          }
        });
    } else {
      setError('Las contraseñas no coinciden')
      return false
    }
  }


  return (<div>
    <h4>Cambio de contraseña</h4>
    <div>
      <div className="w-full  mb-8">
        <Input className="w-full"
          //  id="standard-adornment-password"
          type={showCurrentPassword ? 'text' : 'password'}
          value={passwordState.currentPassword}

          placeholder="Contraseña actual"
          onChange={({target}) => handleUpdatePassword(target.value, 'currentPassword')}
          startAdornment={<InputAdornment position="start">
            <LockIcon style={{ color: "#6B6B6B" }}></LockIcon>
          </InputAdornment>}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              // onMouseDown={handleMouseDownPassword}
              >
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className="w-full  mb-8">
        <Input className="w-full"
          //  id="standard-adornment-password"
          type={showNewPassword ? 'text' : 'password'}
          value={passwordState.newPassword}

          placeholder="Nueva contraseña"
          onChange={({target}) => handleUpdatePassword(target.value, 'newPassword')}
          startAdornment={<InputAdornment position="start">
            <LockIcon style={{ color: "#6B6B6B" }}></LockIcon>
          </InputAdornment>}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPassword(!showNewPassword)}
              // onMouseDown={handleMouseDownPassword}
              >
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className="w-full  mb-8">
        <Input className="w-full"
          //  id="standard-adornment-password"
          type={showConfirmPassword ? 'text' : 'password'}
          value={passwordState.confirmPassword}

          placeholder="Confirmar contraseña"
          onChange={({target}) => handleUpdatePassword(target.value, 'confirmPassword')}
          startAdornment={<InputAdornment position="start">
            <LockIcon style={{ color: "#6B6B6B" }}></LockIcon>
          </InputAdornment>}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              // onMouseDown={handleMouseDownPassword}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>

    </div>
    {error ? (
      <div className="text-red-600 text-center mb-4">{error}</div>
    ) : null}
    {
      success ? (
        <div className="text-sc-primary text-center mb-4">La contraseña ha sido actualizada exitosamente</div>
      ) : null
    }
    <div
      className='mt-8 mx-auto py-4 md:pt-2' style={{ width: '281px', height: '44px' }}
      onClick={() => { verifyPasswordCurrent() }}
    >
      {/* <Link to="/"> <SCButton>Confirmar</SCButton> </Link> */}
      <SCButton>Cambiar Contraseña</SCButton>
    </div>
  </div>)
}

export default ChangePasswordForm