import * as React from "react"
import SCButton from "../../buttons/SCButton"


const ActivitySettingsForm = ({onRequestPasswordChange}) => {

    return(<div>
         <h3 className="text-sc-titles">Actividad de la cuenta</h3>
            <p>Protege tu cuenta</p>
            <p>¿Detectaste un inicio de sesión o actividad inusual en tu cuenta? ¡No te preocupes, estamos para ayudarte!</p>
            <p>La seguridad de tu cuenta es muy importante para nosotros, por eso trabajamos en protocolos que impidan que otros usuarios inicien sesión sin tu autorización. Cuando detectamos un intento de inicio de sesión desde una nueva ubicación o un nuevo dispositivo, ayudamos a proteger tu cuenta enviándote un mensaje de correo electrónico en donde te alertamos sobre la actividad de la misma, por este motivo es muy importante mantener tu información de contacto actualizada en todo momento, así podremos contactarte de inmediato en caso de detectar alguna actividad inusual.</p>
            <p>Si inicias sesión en tu cuenta mientras estás de viaje o si instalas la aplicación en un nuevo dispositivo, te enviaremos una alerta junto a un código de seguridad para verificar tu identidad y que tu cuenta esté segura siempre.</p> 
            <p>No olvides que tu cuenta es personal e intransferible.</p>
            <div className="flex justify-center">
                <div style={{maxWidth:250}}>
                    <SCButton onClick={onRequestPasswordChange}>Cambiar contraseña</SCButton>                    
                </div>
            </div>

    </div>)
}

export default ActivitySettingsForm;