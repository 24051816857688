import * as React from "react"
import SCButton from "../../components/buttons/SCButton"
import ActivitySettingsForm from "../../components/forms/security/activityForm"
import ChangePasswordForm from "../../components/forms/security/changePassword"
import SettingsMenu from "../../components/SettingMenu"
import SideBarLayout from "../../components/SideBarLayout"

const SecuritySettingsPage = () => {
    const [form, setForm] = React.useState("activity")
    return(<SideBarLayout>
        <SettingsMenu>            
            {form==="activity" ? <ActivitySettingsForm onRequestPasswordChange={()=>setForm("passwordChange")}></ActivitySettingsForm> : null}
            {form==="passwordChange" ? <ChangePasswordForm></ChangePasswordForm> : null}
           
        </SettingsMenu>
    </SideBarLayout>)
}

export default SecuritySettingsPage