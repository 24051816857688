import * as React from "react"
import { navigate, Link } from "gatsby"
import { FiArrowLeftCircle } from "@react-icons/all-files/fi/FiArrowLeftCircle"

const SettingsMenu = ({ location, children }) => {
    const goSettings = () => {
        navigate("/settings")
    }
    return (
        <div className="flex flex-wrap md:flex-row md:flex-nowrap md:flex-1">
            <aside className="w-full h-auto border shadow hidden md:w-64 md:h-screen md:mr-8 md:block md:h-5/6">
                <div className="md:min-w-min md:pr-6">
                    <h2 className="pl-8 mt-16 text-sc-titles">
                        <b>Configuración</b>
                    </h2>
                    <ul>
                        <li className="pl-4 pr-8">
                            <Link
                                to="/settings/status"
                                className="text-sc-titles"
                                style={{ fontSize: 16 }}
                            >
                                <div className="p-4">
                                    <b>Estatus de la cuenta</b>
                                </div>
                                <hr className="mt-4"></hr>
                            </Link>
                        </li>

                        <li className="pl-4 pr-8">
                            <Link
                                to="/settings/security"
                                className="text-sc-titles"
                                style={{ fontSize: 16 }}
                            >
                                <div className="p-4">
                                    <b>Conectividad</b>
                                </div>
                                <hr className="mt-4"></hr>
                            </Link>
                        </li>

                        <li className="pl-4 pr-8">
                            <Link
                                to="/settings/deleteAccount"
                                className="text-sc-titles"
                                style={{ fontSize: 16 }}
                            >
                                <div className="p-4">
                                    <b>Eliminar cuenta</b>
                                </div>
                                <hr className="mt-4"></hr>
                            </Link>
                        </li>
                    </ul>
                </div>
            </aside>
            {(children && children.length) ? (
                <div className="mt-14 flex flex-col h-screen md:w-full md:mt-0">
                    <div className="flex items-center h-auto md:hidden">
                        <FiArrowLeftCircle
                            style={{
                                color: "#000",
                                cursor: "pointer",
                                marginRight: "10px",
                                fontSize: "1.25rem",
                            }}
                            onClick={goSettings}
                        />
                        <p className="mb-0 pb-0">Configuración</p>
                    </div>

                    <main className="relative z-0 flex-1 h-5/6 overflow-y-auto p-8 border shadow mt-10 md:mt-0 md:w-full md:flex-col md:items-start">
                        {children}
                    </main>
                </div>
            ) : (
                <main className="hidden  md:mt-0 md:w-full md:flex-col md:flex-1 md:shadow md:h-screen md:items-start md:block md:p-8">
                {children}
            </main>
            )}
        </div>
    )
}

export default SettingsMenu
